<template>
  <loader :is-loading="clientLoading" />
  <div class="max-w-screen-md space-y-6">
    <lf-card v-if="!clientLoading" class="py-6 mb-8">
      <loader :is-loading="isSubmitting" />
      <div>
        <access-control :roles="[SUPER_ADMIN, ADMIN]">
          <div class="pb-6 pt-2" :class="{ 'border-b': !isAdmin }">
            <lf-switch
              v-model="selectedAllServices"
              name="data.stop_experian_dnb_services"
              @toggle-changed="handleServiceSelectAll"
            >
              <lf-h3>
                {{ $t("ORGANIZATION.INQUIRIES.TURN_ON_INQUIRIES") }}
              </lf-h3>
            </lf-switch>
          </div>
        </access-control>
        <div
          class="-mx-6 pr-6"
          :class="{
            'bg-gray-100 border-b border-t pt-6': isAdmin,
            'px-6 mt-4': !isAdmin
          }"
        >
          <lf-h3 v-if="!isAdmin || isClientAdmin">
            {{ $t("ORGANIZATION.INQUIRIES.BOP_SERVICES_SUBSCRIBED") }}
          </lf-h3>
          <template
            v-if="
              (isAdmin || !client.stop_experian_dnb_services) &&
              !isFundingAdvisor
            "
          >
            <access-control :roles="[ADMIN, SUPER_ADMIN]">
              <div class="grid grid-cols-2" :class="{ 'mt-4': !isAdmin }">
                <div
                  v-for="(serviceName, serviceKey) in businessCreditServices"
                  :key="serviceKey"
                >
                  <lf-checkbox
                    v-model="selectedServices"
                    class="mb-4"
                    name="data.has_middesk"
                    :class="{ 'ml-8': isAdmin }"
                    :value="serviceKey"
                  >
                    {{ serviceName }}
                  </lf-checkbox>
                </div>
              </div>
            </access-control>
            <access-control
              :roles="[...CLIENT_ROLE_GROUP, FUNDING_ADVISOR, UNDERWRITER]"
            >
              <template v-if="Object.keys(selectedBopServices).length">
                <div
                  v-for="(serviceName, serviceKey) in selectedBopServices"
                  :key="serviceKey"
                >
                  <lf-h4 class="mt-4">
                    <span class="text-checkmark-green mr-2">
                      <icon-base icon="success-round-big" />
                    </span>
                    {{ serviceName }}
                  </lf-h4>
                </div>
              </template>
              <div v-else>
                <business-credit-contact />
              </div>
            </access-control>
          </template>
          <template v-else>
            <div class="my-8">
              <business-credit-contact />
            </div>
          </template>
        </div>
        <access-control :roles="[ADMIN, SUPER_ADMIN]">
          <div
            class="flex justify-end items-center min-w-full pt-6 pr-5"
            :class="{
              'border-t': !Object.keys(selectedBopServices).length || !isAdmin
            }"
          >
            <primary-button
              type="button"
              :disabled="isSubmitting"
              @click="submit"
            >
              {{ $t("COMMON.SAVE_CHANGES") }}
            </primary-button>
          </div>
        </access-control>
      </div>
    </lf-card>
    <experian-subcodes />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";
import { useRoute } from "vue-router";
import type { EXPERIAN_BUSINESS_OWNER_PROFILE } from "@/helpers/constants";
import {
  SUPER_ADMIN,
  ADMIN,
  CLIENT_ROLE_GROUP,
  FUNDING_ADVISOR,
  UNDERWRITER
} from "@/helpers/constants";

import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import ExperianSubcodes from "@/components/profile/ExperianSubcodes.vue";
import type { IClient } from "@/models/clients";
import type {
  BusinessCreditService,
  IBusinessCreditServices
} from "@/models/options";

import BusinessCreditContact from "@/views/profile/components/BusinessCreditContact.vue";

type BusinessCreditServices = Pick<
  IBusinessCreditServices,
  typeof EXPERIAN_BUSINESS_OWNER_PROFILE
>;

const emit = defineEmits<{
  (eventName: "updated"): void;
}>();
const { getters, dispatch } = useStore();
const { isFundingAdvisor, isAdmin, isUnderwriter, isClientAdmin } = useAuth();
const route = useRoute();
const selectedAllServices = ref(false);
const isSubmitting = ref(false);

const selectedBopServices = computed(() =>
  Object.fromEntries(
    Object.entries(businessCreditServices.value || {}).filter(([value]) =>
      selectedServices.value.includes(value as BusinessCreditService)
    )
  )
);

const client = computed<IClient>(() => ({
  ...getters[
    route.name === "ClientOrganization"
      ? "auth/authClientSettings"
      : "clients/active"
  ]
}));

const selectedServices = ref(client.value.business_credit_services || []); //has to be after client

const businessCreditServicesKeys = computed(
  () => Object.keys(businessCreditServices.value) as BusinessCreditService[]
);

const businessCreditServices = computed<BusinessCreditServices>(
  () => getters["options/experianBopBusinessCreditServices"]
);

onMounted(() => {
  selectedAllServices.value = businessCreditServicesKeys.value.every(
    (service: BusinessCreditService) => selectedServices.value.includes(service)
  );
});

const updateAction = computed(() =>
  route.name === "ClientOrganization"
    ? "auth/updateAuthClientSettings"
    : "clients/updateClient"
);

const clientLoading = computed<boolean>(
  () => getters["clients/clientsLoading"]
);

const handleServiceSelectAll = () => {
  const value = !selectedAllServices.value;
  selectedServices.value = value
    ? [
        ...new Set([
          ...selectedServices.value,
          ...businessCreditServicesKeys.value
        ])
      ]
    : selectedServices.value.filter(
        (service) => !businessCreditServicesKeys.value.includes(service)
      );
};

const submit = async () => {
  if (isUnderwriter || isFundingAdvisor) {
    return;
  }
  const data = {
    business_credit_services: [...selectedServices.value]
  };
  isSubmitting.value = true;
  const response = await dispatch(
    updateAction.value,
    route.name === "ClientOrganization" ? data : { id: client.value.id, data }
  );
  if (response.business_credit_services) {
    selectedServices.value = response.business_credit_services;
  }
  isSubmitting.value = false;
  emit("updated");
};

watch(selectedServices, () => {
  selectedAllServices.value = businessCreditServicesKeys.value.every(
    (service: BusinessCreditService) => selectedServices.value.includes(service)
  );
});

watch(
  () => client.value.business_credit_services,
  (val) => {
    selectedServices.value = val;
  }
);
</script>
